<template>
    <div class="box">
        <img src="./../assets/logo.png" alt="" width="180px" height="62px">
        <div class=" login mt-5 pb-2 pt-2">
            <Toast /> 
            <!-- <h3>Login form</h3> -->
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-field">
                        <div class="p-inputgroup">
                            <InputText type="text" v-model="account.username" placeholder="Username"  :class="[isLoading == true ? 'disabledInput': '' ]" @keyup.enter="checkLogin()"/>
                        </div>
                    </div>
                    <div class="p-field">
                        <div class="p-inputgroup">
                            <span class="p-input-icon-right">
                                <InputText :type="[isShowpassword ? 'password': 'text' ]" v-model="account.password" placeholder="Password" :class="[isLoading == true ? 'disabledInput': '' ]"  @keyup.enter="checkLogin()" />
                                <i class="pi pi-eye" v-if="isShowpassword" @click="showPassword()"/>
                                <i class="pi pi-eye-slash" v-else @click="showPassword()"/>
                            </span>
                        </div>
                    </div>
                    <div class="p-field">
                        <Button label="Login" :class="[isLoading == true ? 'disabledInput': '' ]" class="btn-login" :loading="isLoading" @click="checkLogin()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Login',
    data() {
        return {
            URL:'https://webhooks.mongodb-realm.com/api/client/v2.0/app/marketingcalendar-pszkl/service/SyncData/incoming_webhook/wh1',
            secret: "irP2J8TwB3caCHe",
            account: {
                username:'',
                password: '',
            },
            ipUserLogin:'',
            countNumberLoginSameIP:0,
            isLoading: false,
            isShowpassword: true
        }
    },
    created () {
        this.checkLogin()
    },
    mounted() {
        let self = this
        if (this.$route.path == 'login'){
            window.addEventListener('keyup', function(event) {
                if (event.keyCode === 13) { 
                    self.Login();
                }
            });
        }
    },
    methods: {
        showPassword(){
            this.isShowpassword = !this.isShowpassword
        },
        checkLogin(){
            if (localStorage.getItem("jwt") !== null && localStorage.getItem("username") !== null && localStorage.getItem("secret") !== null ) {
                this.$router.push({name:'Home'})
            } else {
                this.Login()
            }
        },
        Login(){
            if (this.account.username == '') {
                this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Login fail!, Please enter your Username', 
                        life: 3000
                    });
            } else {
                if (this.account.password == '') {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Login fail!, Please enter your password', life: 3000
                    });
                } else {
                    this.isLoading = true
                    let bodyFormData = new FormData();
                        bodyFormData.append('u', this.account.username);
                        bodyFormData.append('p', this.account.password);
                    // let encode_URL =  this.URL + '?secret=' + this.secret
                    
                    let self = this
                    self.axios({
                        method: "post",
                        url: this.$urlAPI,
                        data: bodyFormData,
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                    .then(function (response) {
                        // console.log("Res: " + JSON.stringify(response))
                        if (response.data.status == 'failed') {
                            self.$toast.add({
                                severity:'error', 
                                summary: 'Error Message', 
                                detail:'Your Username or password has wrong', life: 3000
                            });
                        // self.checkIpLogin() // call function check login ip
                        } else {
                            localStorage.setItem('jwt',response.data.loginToken)
                            localStorage.setItem('username',self.account.username)
                            localStorage.setItem('secret',self.secret)
                            self.$toast.add({
                                severity:'success', 
                                summary: 'Success Message', 
                                detail:'Login success', 
                                life: 3000
                            });
                            self.$router.push({name:'Home'})
                        }
                    })
                    .catch(function (error) {
                        self.$toast.add({
                            severity:'error', 
                            summary: 'Error Message', 
                            detail:'Err' + error, life: 3000
                        });
                    }).finally(() => {
                        self.isLoading = false
                    });
                }
            }
        }
    }
    
}
</script>
<style scoped>
.box{
    margin-top: 80px;
}
.login {
    background:#fff;
    max-width: 350px;
    display: block;
    margin: 20px auto;
    /* border: 1px solid rgb(51, 95, 241); */
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 5px #a09999;
}
.login label{
    display: inline;
    float: left;
}
.login .p-field input{
    line-height: 30px;
}
.login .p-field:first-child{
    margin-top: 30px;
    margin-bottom: 15px;
}
.login .p-field input:focus{
    box-shadow: none;
}
.login h3{
    text-align: center;
}
.icon-loading{
    width: 1rem;
    height: 1rem;
}
.disabledInput{
    pointer-events: none;
    background:rgb(145, 144, 144);
}
.btn-login{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    height: 50px;
    background: #273990;
    border-radius: 10px;
}
.p-inputtext{
    width: 100% !important;
}
.p-input-icon-right{
    width: 100%;
}
</style>
